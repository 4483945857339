<template>
    <div class="ParticipateTab-container">
        <!--发布方式TAB按钮：...-->
        <div class="way-tab">
            <div class="wt-label fl">{{ $t('myProject.releaseMode') }}</div>
            <!--        我参与的项目-->
            <div class="wt-area fl">
                <div :class="{ active: tabIndex === 0 }" @click="changeTab(0)" class="wt-btn">
                    {{ $t("myProject.all") }}
                </div>
                <div :class="{ active: tabIndex === 1 }" @click="changeTab(1)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.interestTotal>0">{{ unRead.interestTotal<10 ?
                        unRead.interestTotal : "10+" }}
                    </div>
                    {{ $t("myProject.iAmInterestedInFreeCommunicationProjects") }}
                </div>
                <div :class="{ active: tabIndex === 2 }" @click="changeTab(2)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.privacyTotal>0">{{ unRead.privacyTotal<10 ?
                        unRead.privacyTotal : "10+" }}
                    </div>

                    {{ $t("myProject.vipPrivateRecommendation") }}
                </div>

                <div :class="{ active: tabIndex === 3 }" @click="changeTab(3)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.recommendByMeTotal>0">{{ unRead.recommendByMeTotal<10 ?
                        unRead.recommendByMeTotal : "10+" }}
                    </div>

                    {{ $t("myProject.itemsIRecommendToOtherMembers") }}
                </div>
                <div :class="{ active: tabIndex === 4 }" @click="changeTab(4)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.recommendForMeTotal>0">{{ unRead.recommendForMeTotal<10 ?
                        unRead.recommendForMeTotal : "10+" }}
                    </div>

                    {{ $t("myProject.otherMembersRecommendProjectsToMe") }}
                </div>
                <div :class="{ active: tabIndex === 5 }" @click="changeTab(5)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.viewTotal>0">{{ unRead.viewTotal<10 ? unRead.viewTotal :
                        "10+" }}
                    </div>

                    {{ $t("myProject.projectsIBrowsed") }}
                </div>
                <div :class="{ active: tabIndex === 6 }" @click="changeTab(6)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.multiInviteTotal>0">{{ unRead.multiInviteTotal<10 ?
                        unRead.multiInviteTotal : "10+" }}
                    </div>

                    {{ $t("myProject.multiPersonCommunication") }}
                </div>
            </div>
        </div>
        <!--表格(我参与的项目)-->
        <Table1 :list="list" :tabindex="tabIndex" ref="Table1"/>
        <div style="text-align: center;padding-top: 20px" v-if="list.length==0">{{ $t('myWallet.noDataAvailable') }}
            <!--暂无数据--></div>

        <el-pagination
                :current-page.sync="pageNum"
                :page-size="pageSize"
                :total="total"
                @current-change="handleCurrentChange"
                layout="prev, pager, next,slot"
                style="text-align: center"
                v-if="total !== 0"
        >
            <slot>
                <div class="jump-page">
                    <span>{{ $t('myProject.goTo') }}</span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
    </div>
</template>

<script>
    import Table1 from "@/components/my_project/Table1";
    import {myParticipateList, selectStatisticsForType} from "@/api/myProjectApi";
    import {pageMixin} from "@/mixins";
    import Loading from "@/components/loading";

    export default {
        name: "ParticipateTab",
        mixins: [pageMixin],
        data() {
            return {
                tabIndex: 0,
                isLoading: false,
                unRead: {
                    recommendByMeTotal: 0,
                    recommendForMeTotal: 0,
                    interestTotal: 0,
                    multiInviteTotal: 0,
                    privacyTotal: 0,
                    viewTotal: 0
                },
            };
        },
        components: {
            Table1,
            Loading,
        },
        async created() {
            this.getDataList();
            this.selectStatisticsForType();
        },
        methods: {
            changeTab(index) {
                this.tabIndex = index;
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getDataList();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.getDataList();
            },
            //请求数据列表（我参与的项目列表）
            async getDataList() {
                let formData = {
                    dataType: this.tabIndex,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    token: localStorage.getItem("token"),
                };
                this.isLoading = true;
                let res = await myParticipateList(formData);
                this.isLoading = false;
                this.total = res.data?.total ?? 0;
                this.list = res.data?.list ?? [];
                //await this.$refs.Table1.get_data(this.list);
            },
            async selectStatisticsForType() {
                let formData = {
                    token: localStorage.getItem("token"),
                };
                let res = await selectStatisticsForType(formData);
                if (res.code == 200) {
                    this.unRead = res.data;
                }
            },

            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getDataList();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .way-tab {
        overflow: hidden;
        background: #fafafa;
        border-top: 1px solid #e5e5e5;
        margin-top: 39px;
    }

    .wt-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 47px;
        color: #949494;
        margin: 0 16px;
        display: inline-block;
    }

    .wt-area {
        //overflow: hidden;
        /*height: 100px;*/
    }

    .wt-btn {
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 16px;
        height: 32px;
        background: #ffffff;
        border-radius: 131px;
        margin-bottom: 6px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        color: #000000;
        text-align: center;
        margin-right: 16px;
        margin-top: 8px;
        float: left;
        position: relative;

        .wt-btn-badge {
            height: 18px;
            position: absolute;
            top: 0;
            right: 10px;
            background-color: lightslategray;
            line-height: 16px;
            border-radius: 10px;
            transform: translateY(-50%) translateX(100%);
            color: #fff;
            font-size: 12px;

            padding: 0 6px;
            text-align: center;
            white-space: nowrap;
        }
    }

    .active {
        border: 1px solid #707070;
        background: #000;
        color: white;
    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #fafafa;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;
    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
